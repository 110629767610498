import React, { useState } from 'react'
import { InputGroup, Form, Button } from 'react-bootstrap';
import search from '../../../../assets/images/icons/search.png';
import { Link } from 'react-router-dom'
import { useRef } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import Fuse from 'fuse.js';

const TopSearchBar = () => {
    const user = JSON.parse(localStorage.getItem("user") || {})
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const ref = useRef();
    useOnClickOutside(ref, () => onClickHandler());

    // ["sub_super_admin","admin","sub_admin","interpreter","coordinator","student"]

    const searchArray = [
        { role: ["sub_super_admin", "sub_admin", "interpreter", "coordinator", "student"], name: "dasboard", label: "Dashboard", redirection: "dashboard" },
        { role: ["sub_super_admin", "sub_admin", "admin"], name: "users", label: "Users", redirection: "users" },
        { role: ["sub_super_admin", "coordinator"], name: "students ", label: "Students ", redirection: "students" },
        { role: ["sub_super_admin", "sub_admin"], name: "packages", label: "Packages ", redirection: "packages" },
        { role: ["sub_super_admin", "sub_admin"], name: "packages", label: "Packages (Add Packages )", redirection: "packages/create" },
        { role: ["sub_super_admin", "sub_admin"], name: "Organizations ", label: "Organizations ", redirection: "Organizations" },
        { role: ["sub_super_admin", "sub_admin", "admin"], name: "interpretations ", label: "Interpretations", redirection: "interpretations?tab=requested" },
        { role: ["sub_super_admin", "sub_admin", "admin"], name: "interpretations_in_progress ", label: "Interpretations ( In Progress )", redirection: "interpretations?tab=progress" },
        { role: ["sub_super_admin", "sub_admin", "admin"], name: "interpretations_under_review ", label: "Interpretations ( Under Review )", redirection: "interpretations?tab=review" },
        { role: ["sub_super_admin", "sub_admin", "admin"], name: "interpretations_completed ", label: "Interpretations ( Completed )", redirection: "interpretations?tab=completed" },
        { role: ["sub_super_admin", "sub_admin", "interpreter", "admin", "coordinator", "student"], name: "support ", label: "Support ", redirection: "support" },
        { role: ["sub_super_admin", "sub_admin", "interpreter", "admin", "coordinator", "student"], name: "support_in_progress ", label: "Support ( In Progress )", redirection: "support?tab=progress" },
        { role: ["sub_super_admin", "sub_admin", "interpreter", "admin", "coordinator", "student"], name: "support_resolved ", label: "Support (Resolved)", redirection: "support?tab=resolved" },
        { role: ["sub_super_admin", "sub_admin", "interpreter", "admin", "coordinator", "student"], name: "chat ", label: "Chat", redirection: "chat" },
        { role: ["sub_super_admin", "interpreter", "coordinator"], name: "assignments ", label: "Assignments", redirection: "assignments" },
        { role: ["sub_super_admin", "interpreter", "coordinator"], name: "assignments_in_progress ", label: "Assignments ( progress )", redirection: "assignments?tab=progress" },
        { role: ["sub_super_admin", "interpreter", "coordinator"], name: "assignments_under_review ", label: "Assignments ( review )", redirection: "assignments?tab=review" },
        { role: ["sub_super_admin", "interpreter", "coordinator"], name: "assignments_completed ", label: "Assignments ( Completed )", redirection: "assignments?tab=completed " },
        { role: ["sub_super_admin", "interpreter", "interpreter"], name: "salary ", label: "Salary", redirection: "salary" },
        { role: ["sub_super_admin", "admin"], name: "subscriptions ", label: "Subscriptions", redirection: "subscriptions/professional" },
        { role: ["sub_super_admin", "admin"], name: "payments ", label: "Payments", redirection: "payments" },
        { role: ["sub_super_admin", "admin"], name: "classes ", label: "Classes", redirection: "divisions" },
        { role: ["sub_super_admin", "admin"], name: "classes_add_class ", label: "Classes (Add Class)", redirection: "divisions/create" },
        { role: ["sub_super_admin", "coordinator"], name: "profile ", label: "Profile", redirection: "profile" },
        { role: ["sub_super_admin", "coordinator", "student"], name: "library ", label: "Library", redirection: "library" },

    ]

    const options = {
        keys: ['label'],
        includeScore: true,
        threshold: 0.4, // Adjust the threshold for fuzziness
        distance: 100, // Allow for small spelling mistakes
    };
    const fuse = new Fuse(searchArray, options);

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);

        const fuzzyResults = fuse.search(searchTerm);

        const filteredResults = fuzzyResults.map((result) => result.item);

        setSearchResults(filteredResults);
    };

    const onClickHandler = () => {
        setSearchTerm('')
        setSearchResults([])
    }

    return (
        <div>
            <InputGroup className="">
                <Form.Control
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    autoComplete="off"
                />
                <Button variant="outline-secondary" id="button-addon2">
                    <img src={search} alt="Search" />
                </Button>
            </InputGroup>
            <ul className='search-table' ref={ref}>
                {searchResults.filter((res) => res?.role?.includes(user?.role)).map((result) => (
                    <li key={Math.random()} className='search-list' onClick={onClickHandler}>
                        <Link to={result.redirection}>{result.label}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TopSearchBar